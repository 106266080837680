@import '~font-awesome/scss/font-awesome.scss';
@import 'vars';

h1 {
  text-transform: uppercase;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  color: $title-color;

  .subtitle {
    display: block;
    text-transform: none;
    font-weight: 400;
    font-family: "Grand Hotel", sans-serif;
  }
}

h2 {
  color: $rollover-color;
  text-transform: uppercase;
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 2em;
}

h3 {
  color: $title-color;
  font-family: "Grand Hotel", cursive;
  font-weight: 400;
  font-size: 1.8em;
  //font-size: .60rem;
}

.text-grey {
  color: #878786;
}

.more {}

.separator {
  display: block;
  width: 3.5em;
  border-bottom: #AAA .3em solid;
  margin-top: 1em;
  margin-bottom: 1em;
}

.margin-auto {
  margin-left: auto;
  margin-right: auto;
}

.puce {
  color: $title-color;
}

.content, .home-content {
  p,
  ul li,
  ol li,
  b,
  strong,
  td,
  th {
    font-family: 'Quicksand', sans-serif;
    color: $main-content-color;
  }

  b, strong {
    font-weight: 600;

  }

  .sous-titre {
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.2em;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  ul {
    margin: 0 0 6px 24px;
    padding-left: 0;

    li {
      position: relative;
      list-style: none;

      &:before {
        content: ' ';
        display: table-cell;
        background-color: $rollover-color;
        width: 1em;
        height: 1em;
        position: absolute;
        top: .1em;
        left: -1.5em;
        border-radius: 50%;
      }
    }
  }

  ol {
    margin: 0 0 .5em .5em;
    padding-left: 0;
    counter-reset: my-awesome-counter;

    li {
      list-style: none;
      position: relative;
      counter-increment: my-awesome-counter;
      line-height: 2.5em;

      &:before {
        padding: 0.3em;
        content: "0" counter(my-awesome-counter);
        color: $rollover-color;
        border: 2px solid $rollover-color;
        text-align: center;
        border-radius: 50%;
        margin-right: .5em;
        width: 2em;
        height: 2em;
      }
    }
  }

  h2 {
    font-weight: 700;
  }

  h3 {
  }

  a:link,
  a:visited {
    text-decoration: underline;
  }

  a:hover {
    text-decoration: none;
  }

  .on-off {
    cursor: pointer;
    text-decoration: underline;

    &:after {
      font-family: "FontAwesome";
      text-align: center;
      display: inline-block;
      margin-left: 1em;
      @media (min-width: 576px) {
        color: #FFF;
        background: url(../images/on-off.png) no-repeat -25% 50%;
        background-size: contain;
        padding: 1em 0;
        width: 125px;
      }
      //height: 76px;
    }

    &.on:after {
      content: $fa-var-arrow-up;
    }

    &.off:after {
      content: $fa-var-arrow-down;
    }
  }

  .accordeon {
    padding: 0 0 0 40px;
    color: #399;
  }

  .voyage-quotidien {
    margin-bottom: 1rem;
  }

  .galerie-photos-fond {
    background: #7a7571;

    .picture-link {
      margin: 1rem 0;
    }
  }
}

//
//@media (min-width: 576px) {
//  .content {
//    font-size: 1.2em;
//  }
//}
//@media (min-width: 768px) {
//  .content {
//    font-size: 1.3em;
//  }
//}
//@media (min-width: 992px) {
//  .content {
//    font-size: 1.4em;
//  }
//}
//@media (min-width: 1200px) {
//  .content {
//    font-size: 1.6em;
//  }
//}